import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { API } from 'src/app/services/api.service';
import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { alertService } from 'src/app/services/alert-service.service';

import { data } from 'jquery';

@Component({
  selector: 'app-recompute-score',
  templateUrl: './recompute-score.component.html',
  styleUrls: ['./recompute-score.component.css']
})
export class RecomputeScoreComponent implements OnInit {

  checkInputCert: boolean = false;
  loaderCert: boolean = false;
  end_date: any = moment(new Date()).format("MMM DD, YYYY");
  end_date_reentry: any = moment(new Date()).format("MMM DD, YYYY");
  scores_received: boolean = false;
  leed_id: any = "";
  scores: any;
  projectType: string = 'LEED';

  // Improvement scores

  leed_id_improvement: any = '';
  energyArray: any = [];
  waterArray: any = [];
  wasteArray: any = [];
  transportArray: any = [];
  humanExperienceArray: any = [];
  loader: boolean = false;
  energyScoreInfo: any = {};
  waterScoreInfo: any = {};
  wasteScoreInfo: any = {};
  transportScoreInfo: any = {};
  humanScoreInfo: any = {};

  // Analysis recompute
  energyAnalysisInfo: any = {};
  sourceAnalysisInfo: any = {};
  waterAnalysisInfo: any = {};
  wasteAnalysisInfo: any = {};
  transportAnalysisInfo: any = {};
  humanAnalysisInfo: any = {};
  leed_id_analysis: any = '';
  Object: any = Object;

  // Re-emtry scores
  checkInput: boolean = false;
  leed_id_re_entry: any = '';
  reentry_scores: any = {};
  reentry_scores_received: boolean = false;

  constructor(
    public global: Global,
    private api: API,
    private alertService: alertService,
    public appData: AppData
  ) { }

  checkInputsCert() {
    if (isNaN(this.leed_id) || this.end_date == '') {
      this.checkInputCert = false;
    }
    else {
      this.checkInputCert = true;
    }
  };

  recomputeScore() {
    this.loaderCert = true;
    this.scores_received = false;

    if (this.leed_id > '3900000000' && this.leed_id < '5000000000' )
      {
        this.projectType = 'property';
      }
      else
      {
        this.projectType = 'LEED';
      }

    var url = '/assets/' + this.projectType + ':' + this.leed_id + '/scores/recompute/';
    if (this.end_date != moment(new Date()).format("MMM DD, YYYY")) {

      var score_date = moment(this.end_date).format("YYYY-MM-DD");
      var selected_date: any = score_date.split('-');
      var new_date_obj = new Date(selected_date[0], parseInt(selected_date[1]) - 1, selected_date[2]);

      if (new_date_obj < new Date()) {
        var recompute_end_date = String(new_date_obj.getFullYear()) + '-' + ('0' + String(new_date_obj.getMonth() + 1)).slice(-2) + '-' + ('0' + String(new_date_obj.getDate())).slice(-2);
        url = '/assets/' + this.projectType + ':' + this.leed_id + '/scores/recompute/?at=' + recompute_end_date;
      }
    }

    this.api.get(url).subscribe(
      data => {
        if (data.effective_at == undefined) {
          this.loaderCert = false;
          this.alertService.alert('error', data, 5);
          return;
        }
        this.scores = data;
        Object.keys(this.scores).forEach((k) => {
          if (this.scores[k] != null && (k.indexOf("energy") > -1 || k.indexOf("water") > -1 || k.indexOf("waste") > -1 || k.indexOf("transport") > -1 || k.indexOf("human_experience") > -1 || k.indexOf("subscores") > -1)) {
            this.scores[k] = parseFloat((this.scores[k]).toFixed(2));
          }
        });
        this.loaderCert = false;
        this.scores_received = true;
      },
      error => {
        this.loaderCert = false;
        this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
      }
    );
  };


  enableFdatePicker(): void {
    (<any>$('#end_date')).fdatepicker(
      {
        format: 'M dd, yyyy',
        onRender: (date: any) => {
          return date.valueOf() > new Date().valueOf() ? 'disabled' : '';
        }
      }).on('changeDate', (ev: any) => {
        this.end_date = $('#end_date').val();
      });

      (<any>$('#end_date_reentry')).fdatepicker(
        {
          format: 'M dd, yyyy',
          onRender: (date: any) => {
            return date.valueOf() > new Date().valueOf() ? 'disabled' : '';
          }
        }).on('changeDate', (ev: any) => {
          this.end_date_reentry = $('#end_date_reentry').val();
        });

  }

  // Improvement scores starts

  getImprovementInfo(){
    this.loader = true;
    this.humanExperienceArray = [];

    if (this.leed_id_improvement > '3900000000' && this.leed_id_improvement < '5000000000' )
      {
        this.projectType = 'property';
      }
      else
      {
        this.projectType = 'LEED';
      }

    this.api.get('/assets/' + this.projectType + ':' + this.leed_id_improvement + '/scores/improvement/recompute/').subscribe(
      data => {
        if(data.energy != null && data.energy != "None")
        {
            this.energyArray = [];
            this.energyScoreInfo = data.energy;
        }
        else    
        {
            this.energyScoreInfo = {};
            this.energyArray = [];
        }
        
        if(data.water != null && data.water != "None")
        {
            this.waterArray = [];
            this.waterScoreInfo = data.water;                    
        }

        else
        {
            this.waterScoreInfo = {};
            this.waterArray = [];
        }
        
        if(data.waste != null && data.waste != "None")
        {
            this.wasteArray = [];
            this.wasteScoreInfo = data.waste;                    
        }
        else
        {
            this.wasteScoreInfo = {};
            this.wasteArray = [];
        }
        
        if(data.transportation != null && data.transportation != "None")
        {
            this.transportArray = [];
            this.transportScoreInfo = data.transportation;
            
        }
        else
        {
            this.transportScoreInfo = {};
            this.transportArray = [];
        }
        
        if(data.human_experience != null && data.human_experience != "None")
        {
            this.humanExperienceArray = [];
            this.humanScoreInfo = data.human_experience;
        }
        else
        {
            this.humanScoreInfo = {};
            this.humanExperienceArray = [];
        } 
        
        for(var key in this.energyScoreInfo)
        {
            this.energyArray.push({'attr': key, 'value': this.energyScoreInfo[key]});
        }

        for(var key in this.waterScoreInfo)
        {
            
            this.waterArray.push({'attr': key, 'value': this.waterScoreInfo[key]});                                        
        }                                
        
        for(var key in this.wasteScoreInfo)
        {                    
            this.wasteArray.push({'attr': key, 'value': this.wasteScoreInfo[key]});
        }  

        for(var key in this.transportScoreInfo)
        {
            this.transportArray.push({'attr': key, 'value': this.transportScoreInfo[key]});
        }

        for(var key in this.humanScoreInfo)
        {
            this.humanExperienceArray.push({'attr': key, 'value': this.humanScoreInfo[key]});                                     
        }
                        
        this.loader = false;
      },
      error => {
        this.loader = false;
        this.alertService.alert('error', 'Something went wrong.', 5);
      }
    );
  };

  isValidObj(x: any): x is Object {
    return x != null && typeof x === 'object';
  }
  // Improvement scores ends

  // Analysis starts
  getAnalysisInfo() {
    this.loader = true;

    if (this.leed_id_analysis > '3900000000' && this.leed_id_analysis < '5000000000' )
      {
        this.projectType = 'property';
      }
      else
      {
        this.projectType = 'LEED';
      }

    this.api.get('/assets/' + this.projectType + ':' + this.leed_id_analysis + '/analysis/recompute/?invocation_type=sync').subscribe(
      data => {
        if (data.result.energy !== null && !data.result.energy.errors) {
          this.energyAnalysisInfo = data.result.energy;          
        }
        else {
          this.energyAnalysisInfo = {};
        }
        if (data.result.source !== null && !data.result.source.errors) {
          this.sourceAnalysisInfo = data.result.source; 
        }
        else {
          this.sourceAnalysisInfo = {};
        }

        if (data.result.water !== null && !data.result.water.errors) {
          this.waterAnalysisInfo = data.result.water;
        }
        else {
          this.waterAnalysisInfo = {};
        }

        if (data.result.waste != null && !data.result.waste.errors) {
          this.wasteAnalysisInfo = data.result.waste;
        }
        else {
          this.wasteAnalysisInfo = {};
        }

        if (data.result.transport !== null && !data.result.transport.errors) {
          this.transportAnalysisInfo = data.result.transport;
        }
        else {
          this.transportAnalysisInfo = {};
        }

        if (data.result.human_experience !== null && !data.result.human_experience.errors) {
          this.humanAnalysisInfo = data.result.human_experience;
        }
        else {
          this.humanAnalysisInfo = {};
        }
        if(data.result.energy.errors && data.result.source.errors && data.result.water.errors && data.result.waste.errors && data.result.transport.errors && data.result.human_experience.errors){
          this.alertService.alert('success', 'This property does not have enough data to recompute Analysis', 5);
        }
        this.loader = false;
      },
      error => {
        this.loader = false;
        this.alertService.alert('error', 'Something went wrong.', 5);
      }
    );
  };

  isValidObject(value: any) 
  {
      if (typeof value === "object" && !Array.isArray(value) && value !== null){
        return true;
      }
      else{
        return false;
      }
  };

  // Analysis scores ends

  // Re-entry scores starts

  checkInputs()
  {
      if(isNaN(this.leed_id_re_entry) || this.end_date_reentry == '')

      {
          this.checkInput = false;
      }
      else
      {
          this.checkInput = true;
      }
  };
  
  recomputeReentryScore()
  {
      this.loaderCert = true;
      this.reentry_scores_received = false;
      var score_date = moment($('#end_date_reentry').val()).format("YYYY-MM-DD");

      if (this.leed_id_re_entry > '3900000000' && this.leed_id_re_entry < '5000000000' )
        {
          this.projectType = 'property';
        }
        else
        {
          this.projectType = 'LEED';
        }

      var recompute_url = '/assets/' + this.projectType + ':'+ this.leed_id_re_entry + '/scores/re-entry/recompute/';

      if (score_date != moment(new Date()).format("YYYY-MM-DD")){

          var selected_date: any = score_date.split('-');

          var new_date_obj = new Date(selected_date[0],parseInt(selected_date[1])-1,selected_date[2]);

          if (new_date_obj < new Date()){
              var recompute_end_date = String(new_date_obj.getFullYear()) + '-' + ('0' + String(new_date_obj.getMonth() + 1)).slice(-2) + '-' + ('0' + String(new_date_obj.getDate())).slice(-2);
              recompute_url = '/assets/' + this.projectType + ':'+ this.leed_id_re_entry + '/scores/re-entry/recompute/?at=' + recompute_end_date;
          }
      }

      this.api.get(recompute_url).subscribe(
        data => {
          this.reentry_scores = data;
          this.loaderCert = false;
          this.reentry_scores_received = true;
        },
        error => {
          this.loaderCert = false;
          this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
        }
      );
  };

  ngOnInit(): void {
  }

}
