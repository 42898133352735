<app-sidebar [sidebar_type]="'admin_section'"></app-sidebar>

<app-admin-team *ngIf="appData.get('app_name') == 'admin_team'"></app-admin-team>
<app-team-export *ngIf="appData.get('app_name') == 'team_export'"></app-team-export>
<app-analysis *ngIf="appData.get('app_name') == 'analysis'"></app-analysis>
<app-subscription-discount *ngIf="appData.get('app_name') == 'subscription_discount'"></app-subscription-discount>
<app-cancel-subscription *ngIf="appData.get('app_name') == 'cancel-subscription'"></app-cancel-subscription>
<app-export-data *ngIf="appData.get('app_name') == 'admin_export'"></app-export-data>
<app-certification-base-score *ngIf="appData.get('app_name') == 'admin_certification'"></app-certification-base-score>
<app-review *ngIf="appData.get('app_name') == 'review'"></app-review>
<app-test-project *ngIf="appData.get('app_name') == 'test_project'"></app-test-project>
<app-recompute-score *ngIf="appData.get('app_name') == 'recompute_score'"></app-recompute-score>
<app-sync-project *ngIf="appData.get('app_name') == 'sync_project'"></app-sync-project>
<app-admin-score-version *ngIf="appData.get('app_name') == 'admin_score_version'"></app-admin-score-version>
<app-return-review *ngIf="appData.get('app_name') == 'return_review'"></app-return-review>
<app-user-data-masking *ngIf="appData.get('app_name') == 'user_data_masking'"></app-user-data-masking>
<app-recompute-improvement-scores *ngIf="appData.get('app_name') == 'recompute_improvement_scores'"></app-recompute-improvement-scores>
<app-downgrade-score-version *ngIf="appData.get('app_name') == 'downgrade_score_version'"></app-downgrade-score-version>
<app-recompute-reentry-score *ngIf="appData.get('app_name') == 'recompute_reentry_score'"></app-recompute-reentry-score>
<app-offline-subscription *ngIf="appData.get('app_name') == 'offline_subscription'"></app-offline-subscription>
<app-partial-maintenance *ngIf="appData.get('app_name') == 'partial_maintenance'"></app-partial-maintenance>
<app-data-transfer *ngIf="appData.get('app_name') == 'data_transfer'"></app-data-transfer>
<app-transit-survey *ngIf="appData.get('app_name') == 'transit_survey'"></app-transit-survey>
<app-admin-deactivate *ngIf="appData.get('app_name') == 'admin_deactivate'"></app-admin-deactivate>
<app-recompute-analysisinfo *ngIf="appData.get('app_name') == 'recompute_analysisinfo'"></app-recompute-analysisinfo>
<app-covid-flag *ngIf="appData.get('app_name') == 'covid_flag'"></app-covid-flag>
<app-admin-pre-payment *ngIf="appData.get('app_name') == 'admin_pre_payment'"></app-admin-pre-payment>
<app-export-racetrack *ngIf="appData.get('app_name') == 'export_racetrack'"></app-export-racetrack>
<app-owner-info *ngIf="appData.get('app_name') == 'owner_info'"></app-owner-info>
<app-order-update *ngIf="appData.get('app_name') == 'order_update'"></app-order-update>
<app-surveys-update *ngIf="appData.get('app_name') == 'surveys_update'"></app-surveys-update>

<app-arc-japan-invoice *ngIf="appData.get('app_name') == 'arc_japan_invoice'"></app-arc-japan-invoice>
<app-cagbc-invoice *ngIf="appData.get('app_name') == 'cagbc_invoice'"></app-cagbc-invoice>
