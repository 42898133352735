export const environment =
{
	production: false,
	ENV_FLAG: "STG",

	subscription_key: '2c62cb86aa4245e599113cc43bed6947',
    basic_api_url   : 'https://api.usgbc.org/stg/leed',
    rating_systems  :
	{
		building: 'LEED V4 O+M: EB WP',
		city: 'LEED-CT',
		community: 'LEED-CM',
		district: 'LEED-DT',
		neighborhood: 'LEED-NB',
		transit: 'LEED V4 O+M: TR',
		parksmart: 'PARKSMART',
		sites: 'SITES'
	},
    logout_url      : 'https://stg-arc.gbci.org/arc-user-logout',
    LO_URL          : 'https://leedonline-stg.usgbc.org/',
    LOV2_URL        : 'https://leedonline.usgbc.org/Login.aspx',
    animation_url   : 'api-stg.arconline.io/',
    FRONTEND_BASE_URL: "https://api.usgbc.org/stg/leed",
    DEMO_FLAG       : false,
    LO_PROXY_URL    : "https://leedonline-fileproxy-stg.usgbc.org",
    landingPageURL   : 'https://stg-arc.gbci.org/',
    usgbc_basic_url  : "https://sv2.usgbc.org/",
    insight_url      : "https://api.usgbc.org/skanska-dev",
    v1_applicable_date: '2019-11-06',
    it_subscription_key: 'eab7f75111d84773947fc04ed4b8c959',
    all_arc_material   : [4245, 4253, 4292, 4292, 4641, 4277, 4277, 4640, 4248, 4248, 4642, 4285, 4285, 4291, 4294, 4294, 4646, 4278, 4278, 4644, 4249, 4249, 4647, 4286, 4286, 4293, 4288, 4288, 4621, 4281, 4510, 4245, 4619, 4253, 4619, 4287, 4296, 4289, 4275, 4276, 4295, 3295, 3309, 4600, 4621, 3262, 4689, 4596, 4282, 4682, 4678, 4683, 4680, 4636, 4635, 4288, 4621, 4287, 4700, 4124],
    s3_bucket_url: 'https://stg.app.arconline.io',
    sso_client: "fGhgQeXjMi0W7pTejFrrLJQe95aZFxMO",
    auth0_url: "https://stg-login.usgbc.org/",
	auth0_domain: "stg-login.usgbc.org",
    accounts_page: "https://stg-accounts.usgbc.org/",
    HUB_URL: "https://hub.stg.hub.usgbc.net/home"
}